import en from './en.json';//英文
import zh from './zh.json';//中文
import ja from './ja.json';//日语
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en,
  zh,
  ja,
};
const i18n = new VueI18n({
  locale: 'ja', // set locale
  messages, // set locale messages
});
export default i18n;