import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router';
import http from './http';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import common from './common/function'
import 'swiper/swiper-bundle.css'
import store from './store'
// import VueI18n from 'vue-i18n';
import i18n from './i18n'; // 假设你把语言文件合并到这个文件中

import 'video.js/dist/video-js.css'

// Vue.use(VueI18n);
// const i18n = new VueI18n({
//   locale: 'en', // 设置默认语言
//   messages, // 设置语言信息
// });
Vue.prototype.$http = http
Vue.prototype.common = common
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(Vant);
new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')

// export default i18n; // 确保这里导出 i18n