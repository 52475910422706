<template>
  <div class="container page">
    <van-nav-bar :title="this.videoInfo.vod_name" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="movie-video">
      <!-- <video id="my-video"  class="video-js">
      </video> -->
      <!-- <video id="my-video" class="video-js vjs-default-skin" controls preload="auto" width="640" height="264">
        <source :src="nowPlayVideoUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video> -->
      <video id="my-video" class="video-js vjs-default-skin" controls preload="auto"></video>
    </div>
    <div class="movie-content">
      <div class="movie-descript">
        <p>{{ this.videoInfo.vod_name }}</p>
        <span>{{ this.videoInfo.count }}{{ $t('video.PlayVideo.playCount') }}</span>
      </div>

      <div class="movie-body">
        <div class="movie-title">
          <div>
            <span>{{ $t('video.PlayVideo.hotRecommendations') }}</span>
          </div>
        </div>
        <div class="movie-list">
          <div class="movie-play-item" @click="toPlayVideo(v.id)" v-for="(v, key) in moreVideoInfo" :key="key">
            <div>
              <img :src="v.vod_pic">
              <div>
                <div class="van-count-down">{{ v.time }}</div>
              </div>
            </div>
            <div>
              <p>{{ v.vod_name }}</p>
              <span>{{ v.count }}{{ $t('video.PlayVideo.playCount') }}</span>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
// import videojs from "video.js";
import "videojs-contrib-hls";
export default {
  data() {
    return {
      videoSrc: 'https://eu49.a1e6a123561e5555ac4610a91560c3b5.r2.cloudflarestorage.com/272000/272237/272237_720p.mp4?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=2a2a645851f2acf122c8bd4809ee7f5d%2F20241008%2Fauto%2Fs3%2Faws4_request&X-Amz-Date=20241008T171133Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=9b0f459962dfba43085fe352096f983ea5591f589bf2d65703285427841c38ec',
      nowPlayVideoUrl: "",
      cover: "",
      userInfo: [],
      videoInfo: {},
      moreVideoInfo: {},
      player: null
    };
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    back() {
      this.$router.push({ path: 'Home' })
    },
    getVideoInfo() {
      this.$http({
        method: 'get',
        data: { id: this.$route.query.id },
        url: 'video_get_info'
      }).then(res => {
        this.videoInfo = res.data;
        console.log(this.videoInfo.vod_play_url);
        this.nowPlayVideoUrl = this.videoInfo.vod_play_url;
        
        this.cover = this.videoInfo.vod_pic;
        let videos = document.getElementById('my-video');
        videos.poster = this.cover;
        this.getVideo();
      })

    },
    toPlayVideo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '?id=' + id })
        location.reload();
      }

    },
    getMoreVideoItem() {
      this.$http({
        method: 'get',
        url: 'video_get_more_item'
      }).then(res => {
        this.moreVideoInfo = res.data;
      })
    },
    getVideo() {
  this.player = videojs("my-video", {
    controls: true,
    preload: "auto",
    height: "200px",
    sources: [{
      src: this.nowPlayVideoUrl,
      type: "video/mp4" // 确保正确设置视频类型
    }]
  });
},
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t('video.PlayVideo.accountOffline'));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          } else {
            if (this.$store.getters.getBaseInfo.isplay == 1) {
              this.getVideoInfo();
              this.getMoreVideoItem()
              if (this.userInfo.money <= "0.00") {
                this.$toast(this.$t('video.PlayVideo.pleaseRecharge'));
                this.$router.push({ path: '/Home' })
              }
            } else {
              this.getVideoInfo();
              this.getMoreVideoItem();
            }
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo();
    }
  },
  destroyed() {
    this.player.dispose()
  }
};
</script>

<style scoped>
.video-js {
  width: 100%;
  /*height: 420px;*/
  font-size: 24px;
}

.movie-content {
  flex: 1;
  overflow-y: auto;
}

.movie-content .movie-descript {
  width: 100%;
  height: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
}

.movie-content .movie-descript p {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.movie-content .movie-descript span {
  color: #979799;
}

.movie-content .movie-body {
  width: calc(100% - 20px);
  margin: 0 auto;
}

::v-deep .movie-video .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}

.movie-content .movie-body .movie-title {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie-content .movie-body .movie-title>div:first-child {
  width: 200px;
}

.movie-content .movie-body .movie-title>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.movie-content .movie-body .movie-title>div:first-child span:before {
  content: "";
  display: block;
  width: 8px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 25px;
  margin-right: 10px;
}

.movie-play-item {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  display: flex;
  background-color: #fff;
  margin-bottom: 20px;
}

.movie-play-item>div {
  height: 100%;
}

.movie-play-item>div:first-child {
  width: 200px;
  position: relative;
}

.movie-play-item>div:first-child>img {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
}

.movie-play-item>div:first-child>div {
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, .4);
  border-radius: 0 0 0 10px;
}

.movie-play-item>div:first-child>div .van-count-down {
  color: #fff;
  font-size: 25px;
}

.movie-play-item>div:nth-child(2) p {
  width: 500px;
  height: 60px;
  font-size: 30px;
  line-height: 32px;
  word-break: break-all;
  overflow: hidden;
  color: #000;
}

.movie-play-item>div:nth-child(2) span {
  color: #000;
}

.movie-play-item>div:nth-child(2) {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}
</style>


<!-- <template>
  <div class="movie-video">
    <video id="my-video" class="video-js vjs-default-skin" controls preload="auto" width="640" height="264">
      <source :src="videoSrc" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default {
  data() {
    return {
      videoSrc: 'https://eu49.a1e6a123561e5555ac4610a91560c3b5.r2.cloudflarestorage.com/272000/272258/272258_720p.mp4?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=2a2a645851f2acf122c8bd4809ee7f5d%2F20241008%2Fauto%2Fs3%2Faws4_request&X-Amz-Date=20241008T162333Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=e796714a58a0ff632710c3ec9c6c9e9b49adc8bad318a4bbf006c4ab94d1c87b',
      player: null
    };
  },
  mounted() {
    this.player = videojs('my-video', {
      controls: true,
      autoplay: false,
      preload: 'auto'
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>

<style>
.video-js {
  width: 100%;
}
</style> -->
